import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { footerComponent } from './footer.component';
import { CommonModule } from '@angular/common';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import { I18nModule } from 'src/app/components/translateLoadLazy/translate.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    I18nModule,
    MatToolbarModule,
    MatButtonModule,
  ],
  declarations: [
    footerComponent
  ],
  exports: [footerComponent]
})
export class FooterModule { }
