<div class="main-container">
  <!-- <span >Download TSW+ app for an optimal viewing experience</span> -->
  <span>Install TSW+ for optimal mobile experience</span>
  <div class="android-prompt">
    <button *ngIf="data.mobileType === 'android'" (click)="redirectToPlayStore()">
      Google Play
    </button>
    <button *ngIf="data.mobileType === 'ios'" (click)="redirectToPlayStore()">
      App Store
    </button>
    <button (click)="close()">
      Close
    </button>
  </div>
</div>
