import { Component, Inject, } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: "ios" | "android", url?: string },
    private bottomSheetRef: MatBottomSheetRef<PromptComponent>
  ) { }


  public redirectToPlayStore(): void {
    window.open(this.data.url, "_blank")
    this.close()
  }

  public close() {
    this.bottomSheetRef.dismiss()
  }
}
