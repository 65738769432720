import { environment } from "../../environments/environment"
export const url = 'https://us-central1-tswwrestling-9a710.cloudfunctions.net/api/v1/';
export const config = {
  router: {
    home: '',
    login: 'login',
    register: 'register',
    videos: 'videos',
    subscribe: 'subscribe',
    profile: 'profile',
    myAccount: 'profile/my-account',
    videoDetails: 'details',
    rosterDetails: 'details',
    news: 'news',
    newDetails: 'details',
    tickets: 'tickets',
    rosters: 'rosters',
    legal: 'legal',
    terms_user: 'terms',
  },
  lang: 'en',
  mobile: 999,
  FIREBASE_OPTIONS: {
    URL: url + "collections",
    apiKey: environment.apiKey,
    authDomain: environment.authDomain,
    projectId: environment.projectId,
    appId: environment.appId,
    apiNewsURL: "/news",
    apiRosterURL: "/roster",
    apiTicketsURL: "/tickets",
    apiQuestionsURL: "/questions",
    popupURL: "/popups",
    apiEventsURL: "/events",
    apiPromoBanners: "/promoBanners",
    viewsURL: "/views",
    apiUserURL: "/users",
    baseImagesUrl: "https://firebasestorage.googleapis.com/v0/b/",
    rosterImagesFolderName: "roster-images",
    profileImagesFolderName: "profile-images",
    newsImagesFolderName: "news-images",
    promoBannerFolderName: "promo-banners",
    oFolder: "/o/",
    fileType: "?alt=media",
    storageBucket: "tswwrestling-9a710.appspot.com",
    admin: "admin"
  },
  userStorage: 'user',
  keySecret: 'arbelos',
  urlStripe: url + 'stripe',
  fontStripe: 'https://fonts.googleapis.com/css?family=Quicksand',
};
