import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { config } from './config/config';
import { LoginGuardService as LoginGuard } from './middeleware/login.middeleware';

const routes: Routes = [
  {
    path: config.router.home,
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: config.router.login,
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: config.router.register,
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: config.router.videos,
    loadChildren: () => import('./pages/videos/videos.module').then(m => m.videosModule),
  },
  {
    path: config.router.subscribe,
    loadChildren: () => import('./pages/subscribe/subscribe.module').then(m => m.subscribeModule),
    canActivate: [],
  },
  {
    path: config.router.profile,
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.profileModule),
    canActivate: [LoginGuard]
  },
  {
    path: config.router.news,
    loadChildren: () => import('./pages/news/news.module').then(m => m.newsModule),
    canActivate: []
  },
  {
    path: config.router.tickets,
    loadChildren: () => import('./pages/tickets/tickets.module').then(m => m.ticketsModule),
  },
  {
    path: config.router.rosters,
    loadChildren: () => import('./pages/rosters/rosters.module').then(m => m.RostersModule),
    canActivate: []
  },
  {
    path: config.router.legal,
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalModule),
    canActivate: []
  },
  {
    path: config.router.terms_user,
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalModule),
    canActivate: []
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
