import { Injectable, Injector } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { AngularFireAuth } from "angularfire2/auth";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NotificationService } from "@services/notifications/notifications.services";
import { HttpClient } from "@angular/common/http";
import { config } from "@config/config";
import { from, Observable, of, Subject } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import * as CryptoJS from 'crypto-js';
import { UserService } from "@services/user/user.service";
import { MatDialog } from "@angular/material/dialog";
import { RegisterSuccessMessageComponent } from '@components/register-success-message/register-success-message.component';
import { GlobalState } from "@services/globalServices/globalState.service";
@Injectable({
  providedIn: "root",
})

export class AuthService {
  firebaseOptions = config.FIREBASE_OPTIONS;
  private subscriptioObsv = new Subject();
  constructor(
    private afs: AngularFireAuth,
    private http: HttpClient,
    private router: Router,
    private injector: Injector,
    private userService: UserService,
    public dialog: MatDialog,
    private globalState: GlobalState
  ) {
  }

  async getHeaders() {
    try {
      const newToken = await this.refreshToken().toPromise();
      return new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      });
    } catch (err) {
      throw err;
    }
  }

  async login(email, password): Promise<any> {
    try {
      const userData = await this.afs.auth.signInWithEmailAndPassword(email, password) as any;
      const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(userData), config.keySecret).toString();
      localStorage.setItem(config.userStorage, ciphertext);
      const userId = userData.user.uid;
      return await this.getCurrentUser(userId);
    } catch (err) {
      throw err;
    }
  }

  async passwordReset(email?: string): Promise<any> {
    const successRes = "If this email is in our records, then a password reset link has been sent"
    try {
        if (!email) {
          await this.afs.auth.sendPasswordResetEmail(this.afs.auth.currentUser.email)
        } else {
          await this.afs.auth.sendPasswordResetEmail(email)
        }
        return successRes
    } catch(err) {
      if (err.code === "auth/user-not-found") {
        return successRes
      }
      return err.message
    }
  }

  async register(userData): Promise<any> {
    const _notification = this.injector.get(NotificationService);
    try {
      await this.afs.auth.createUserWithEmailAndPassword(userData.email, userData.password);
      this.afs.auth.currentUser.sendEmailVerification();
      _notification.showSuccessMessage("USER_REGISTER");
      this.successMessage();
    } catch (err) {
      _notification.showSuccessMessage(err.message);
      throw err;
    }
  }

  async getCurrentUser(uid) {
    try {
      const newHeaders = await this.getHeaders() as HttpHeaders;
      return await this.http.get(`${this.firebaseOptions.URL}${this.firebaseOptions.apiUserURL}/${uid}`).toPromise();
    } catch (err) {
      throw err;
    }
  }

  payload(): void {
    const data = localStorage.getItem(config.userStorage);
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data, config.keySecret);
      const dataDecrypt = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(dataDecrypt);
    }
  }

  logout() {
    localStorage.removeItem(config.userStorage);
    const _notification = this.injector.get(NotificationService);
    const translate = this.injector.get(TranslateService);
    translate.get("LOGOUT_MESSAGE").subscribe((res: string) => {
      _notification.showSuccessMessage(res);
    });
    this.globalState.replaySubjectChanged('login', {});
    this.router.navigate([config.router.home]);
  }

  islogin() {
    return (localStorage.getItem(config.userStorage)) ? true : false;
  }

  refreshToken(): Observable<string | null> {
    return this.afs.authState.pipe(take(1),
      switchMap((user) => {
        return (user) ? from(user.getIdToken()) : of(null);
      })
    )
  }
  isSubscription() {
    let user = this.payload() as any;
    if (user) {
      this.userService.getById(user.user.uid).subscribe((data: any) => {
        let dataSub = data['stripe'] ? data['stripe'] : data;
        if (dataSub) {
          if (dataSub['status'] === 'active' && new Date(dataSub['periodEnd'] * 1000) > new Date()) {
            this.subscriptioObsv.next(true);
          } else {
            this.subscriptioObsv.next(false);
          }
        } else {
          this.subscriptioObsv.next(false);
        }
      })
    } else {
      this.subscriptioObsv.next(false);
    }
    return this.subscriptioObsv.pipe(map((value) => value));
  }
  updateUser(email: string) {
    const user = this.afs.auth.currentUser;
    const userData = this.payload() as any;
    const _notification = this.injector.get(NotificationService);
    const _router = this.injector.get(Router);
    user.updateEmail(email).then((data: any) => {
      _notification.showSuccessMessage("EMAIL_EDITED_SUCCESSFULLY");
      this.userService.update(userData.user.uid, { email }).subscribe(() => {

      })
      _router.navigate([config.router.myAccount]);
    }).catch(function (error) {
      _notification.showSuccessMessage(error.message);
      console.log(error);
    });
  }

  successMessage() {
    const newDialog = this.dialog.open(RegisterSuccessMessageComponent);
    newDialog.afterClosed();
  }

}
