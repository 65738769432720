import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-event-alert',
  templateUrl: './event-alert.component.html',
  styleUrls: ['./event-alert.component.scss']
})
export class EventAlertComponent implements OnInit {
  @Input() eventData;
  @Output() closeDialog = new EventEmitter<string>();
  constructor() { 
  }

  ngOnInit(): void {}

  closeEvent() {
    this.closeDialog.emit();
  }
}