import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, of } from 'rxjs';
import { config } from '../../config/config';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [],
  imports: [  
    TranslateModule.forChild({
      defaultLanguage: config.lang,
      loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpClient] },
    })
  ],
  exports: [TranslateModule]
})

export class I18nModule {}
