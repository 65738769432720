import { Component } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { config } from '@config/config';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class headerComponent {
  menuOpen: boolean = false;
  items: any;
  currentUrl: any;
  isLogin: boolean;
  config = config;
  constructor(private router: Router, public auth: AuthService) {
    this.isLogin = this.auth.islogin();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url.replace('/', '').split('?').shift();
        this.isLogin = this.auth.islogin();
      }
    });
    this.items = [
      {
        name: 'HOME',
        section: config.router.home
      },
      {
        name: 'VIDEOS',
        section: config.router.videos
      },
      {
        name: 'NEWS',
        section: config.router.news
      },
      {
        name: 'TICKETS',
        section: config.router.tickets
      },
      {
        name: 'ROSTER',
        section: config.router.rosters
      },
      {
        name: 'STORE',
        section: 'https://texas-style-wrestling.myshopify.com/'
      },
    ];
  }
  async logout() {
    try {
      const logout = await this.auth.logout();
      this.isLogin = this.auth.islogin();
      return logout;
    } catch { }
  }
  goToRoute(url) {
    if (url != null) {
      if (url.includes('http')) {
        window.open(url, '_blank')
      } else {
        if (this.menuOpen) {
          this.menuOpen = false;
        }
        this.router.navigate([url]);
      }
    }
  }
  goToUrl(url) {
    window.open(url, '_blank');
  }
}
