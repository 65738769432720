<div class="dialog-container">
    <div class="dialog-bg" (click)="closeEvent()"></div>
    <div class="dialog">
        <button class="dialog-close-btn" (click)="closeEvent()"><i class="fa fa-close icon-close" aria-hidden="true"></i></button>
        <h1 class="dialog-title">{{ eventData.attributes.title }}</h1>
        <p class="dialog-description">{{ eventData.attributes.description }}</p>
        <a href="{{ eventData.attributes.link }}">
            <div class="dialog-img-cont" [ngStyle]="{'background-image': 'url(' + eventData.attributes.imageUrl + ')'}">
            </div>
        </a>
    </div>
</div>