import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@config/config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class footerComponent {

  email: string = 'contact';
  server: string = 'tswplus.com';
  config = config;
  constructor (private router: Router) {
  }
  goToUrl(url) {
    window.open(url, '_blank');
  }
  goToRouter(url) {
    this.router.navigate([url]);
  }
  mailto() {
    window.open('mailto:contact@tswplus.com', '_blank');
  }
}
