import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { AuthService } from '@services/auth/auth.services';
import { config } from '@config/config';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector
    ) {
    }


    /**
     * intercept all XHR request
     * @param request
     * @param next
     * @returns {Observable<A>}
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;
        const auth = this.injector.get(AuthService);
        auth.refreshToken().subscribe((token: any) => {
          localStorage.setItem('token', token);
        })
        if (localStorage.getItem(config.userStorage)) {
          const token = localStorage.getItem('token');
          if (token) {
            request = req.clone({
              setHeaders: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              }
            });
          }
        }
        /**
         * continues request execution
         */
        return next.handle(request).pipe(catchError((error, caught): any => {
            // intercept the respons error and displace it to the console
            this.handleAuthError(error);
        }) as any);
    }


    /**
     * manage errors
     * @param err
     * @returns {any}
     */
    public handleAuthError(err: HttpErrorResponse) {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          const auth = this.injector.get(AuthService);
          auth.logout();
        }
        throw err;
    }
}
