import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "@components/snackbar/snackbar.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private _snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}
  showSuccessMessage(message) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      duration: 2000,
      horizontalPosition: "end",
      verticalPosition: "top",
    });
  }
}
