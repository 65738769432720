import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@config/config';
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-register-success-message',
  templateUrl: './register-success-message.component.html',
  styleUrls: ['./register-success-message.component.scss']
})
export class RegisterSuccessMessageComponent implements OnInit {

  constructor(
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  goToLogin() {
    this.router.navigate([config.router.login]);
    this.dialog.closeAll();
  }

}
