
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RegisterSuccessMessageComponent } from './register-success-message.component';
import { CommonModule } from '@angular/common';
import { I18nModule } from 'src/app/components/translateLoadLazy/translate.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    MatButtonModule,
    MatDialogModule,
  ],
  declarations: [
    RegisterSuccessMessageComponent
  ],
  exports: [RegisterSuccessMessageComponent]
})
export class RegisterSuccessMessageModule { }
