import {HttpParams} from '@angular/common/http';

export class QueryBuilder {

  static queryToHttpParams (query: object) {
    if (query) {
      let target: HttpParams = new HttpParams();
      Object.keys(query).map((key: string) => {
        const value = query[key];
        if (value && typeof value === 'string' || typeof value === 'number') {
          target = target.append(key, value.toString());
        } else if (Array.isArray(value)) {
          value.map((element) => {
              target = target.append(key, element.toString());
          });
        } else if (typeof value === 'object') {
          Object.keys(value).map((key: string) => {
            let valueSub = value[key];
            if (valueSub && typeof valueSub === 'string' || typeof valueSub === 'number') {
              target = target.append(key, valueSub.toString());
          }
          })
        }
      });
      return target;
    }
  }
  static verifyObject(object) {
    if (object) {
      Object.keys(object).map(function (value, key) {
        if (object[value] === undefined || object[value] === null || object[value] === '' || object[value].length === 0) {
          delete object[value];
        }
      });
    }
    return object;
  }
}
