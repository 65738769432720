import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ViewMediaComponent } from './view-media.component';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { I18nModule } from 'src/app/components/translateLoadLazy/translate.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from "@angular/material/dialog";
import { AngularImageViewerModule } from "@hreimer/angular-image-viewer";
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    I18nModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    AngularImageViewerModule,
    NgxYoutubePlayerModule
  ],
  declarations: [
    ViewMediaComponent
  ],
  exports: [ViewMediaComponent]
})
export class ViewMediaModule { }
