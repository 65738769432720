import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { config } from "@config/config";

@Injectable({
  providedIn: "root",
})

export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  update(id, body) {
    return this.http.put(config.FIREBASE_OPTIONS.URL + config.FIREBASE_OPTIONS.apiUserURL + `/${id}`, body);
  }

  getById(uid) {
    return this.http.get(config.FIREBASE_OPTIONS.URL + config.FIREBASE_OPTIONS.apiUserURL + `/${uid}`);
  }

}