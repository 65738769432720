<div class="bootstrap-wrapper fontainer-flooter">
  <div class="container">
    <div class="row">
      <div class="col-lg-2">
        <h3 class="text-center-mobile">{{'ABOUT' | translate}}</h3>
        <a class="container-center-mobile link-footer color-gray pointer" (click)="goToRouter(config.router.legal)" target="_blank">{{'PRiVACY_POLICY' | translate}}</a>
        <a class="container-center-mobile link-footer color-gray pointer" (click)="goToRouter(config.router.terms_user)" target="_blank">{{'TERMS_OF_USER' | translate}}</a>
      </div>
      <div class="col-lg-3">
        <h3 class="text-center-mobile">{{'CONTACT_US' | translate}}</h3>
        <a class="container-center-mobile link-footer color-gray " target="_blank">{{'EMAIL' | translate}}: <a class="pointer" (click)="mailto()">{{email}}@{{server}}</a></a>
        <a class="container-center-mobile link-footer color-gray " href="https://www.texasstylewrestling.com" target="_blank">{{'WEBSITE' | translate}}: www.texasstylewrestling.com</a>
      </div>
      <div class="col-lg-2">
        <h3 class="text-center-mobile">{{'EXPLORE' | translate}}</h3>
        <a class="container-center-mobile link-footer color-gray " href="https://texas-style-wrestling.myshopify.com/" target="_blank">{{'STORE' | translate}}</a>
        <a class="container-center-mobile link-footer color-gray pointer" (click)="goToRouter(config.router.subscribe)">{{'MEMBERSHIP_PLANS' | translate}}</a>
      </div>
      <div class="col-lg-5 disabled-mobile"></div>
      <div class="col-lg-6">
        <h3 class="text-center-mobile">{{'FIND_US' | translate}}</h3>
        <div class="container-networks">
          <mat-icon class="pointer icon-face icon-networks" (click)="goToUrl('https://www.facebook.com/TXStyleWrestling')" aria-hidden="false" aria-label="Example home icon">facebook</mat-icon>
          <div class="container-icon pointer icon-networks">
            <i class="fa fa-twitter" aria-hidden="true" (click)="goToUrl('https://twitter.com/TSWPlus')"></i>
          </div>
          <div class="container-icon pointer icon-networks">
            <i class="fa fa-instagram" aria-hidden="true" (click)="goToUrl('https://www.instagram.com/tswplus/')"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-12 m-top-30">
        <p class="m-0 text-center-mobile color-gray text-paragraph-small">{{'COPYRIGHT_2021_SWE_ALL_RIGHTS_RESERVED' | translate}}</p>
      </div>
    </div>
  </div>
</div>