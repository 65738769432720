import { Injectable } from '@angular/core';
import { Platform } from "@angular/cdk/platform"
import { MatBottomSheet } from "@angular/material/bottom-sheet"
import { take } from 'rxjs/operators';
import { timer } from 'rxjs';
import { PromptComponent } from '@pages/prompt/prompt.component';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private url: string

  constructor(
    private bottomSheet: MatBottomSheet,
    private platform: Platform
  ) { }

  public promptAppStoreRedirect() {
    if (this.isTswPlusDomain()) {
      if (this.platform.ANDROID) {
          this.url= "https://play.google.com/store/apps/details?id=com.arbelosinteractive.tsw"
          this.openPromptComponent("android")
      }
      if (this.platform.IOS) {
          this.url = "https://apps.apple.com/ca/app/texas-style-wrestling/id1581454519"
          this.openPromptComponent("ios")
      }
    }
  }

  private isTswPlusDomain(): boolean {
    return window.location.hostname.endsWith("tswplus.com")
  }

  private openPromptComponent(mobileType: "ios" | "android") {
    timer(3000)
      .pipe(take(1))
      .subscribe(() => this.bottomSheet.open(PromptComponent, { data: { mobileType, url: this.url } }))
  }
}
