import { AfterContentInit, Component } from '@angular/core';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import { config } from '@config/config';
import { MobileService } from '@services/mobile/mobile.service';
import { PopupService } from '@services/popup/popup.service';
import isWithinInterval from 'date-fns/isWithinInterval';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MobileService]
})
export class AppComponent implements AfterContentInit {
  contentHeight: any;
  hiddenSection: boolean = false;
  isEvent: boolean = false;
  eventData;
  constructor(private mobileService: MobileService, public router: Router, public _popup: PopupService) {
    _popup.get(false, false).then(result => {
      if (result && result.length > 0) {
        const popup = result.find((obj) => {
          return isWithinInterval(new Date(), {
            start: new Date(obj.attributes?.dateFrom),
            end: new Date(obj.attributes?.dateTo)
          });
        });
        if (popup) {
          this.isEvent = true;
          this.eventData = popup;
        }
      }
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngAfterContentInit() {
    this.contentHeight = window.innerHeight - 90 - 440 + 'px';
    this.mobileService.changeResize().subscribe((mobile) => {
      this.contentHeight = window.innerHeight - 90 - 440 + 'px';
    })
  }

  getToday() {
    // 'YYY-MM-DD' FORMAT DATE
    const date = new Date();
    const day = date.getDate() < 10  ? `0${ date.getDate() }` : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? `0${ date.getMonth() + 1 }` : date.getMonth() + 1;
    const year = date.getFullYear();
    return `${ year + '-' + month + '-' + day }`;
  }

  closeDialog() {
    this.isEvent = false;
  }


}
