import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-view-media',
  templateUrl: './view-media.component.html',
  styleUrls: ['./view-media.component.scss']
})
export class ViewMediaComponent implements OnInit {
  type: string;
  url;
  youtubeId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.url = this.data.url;
    if (this.data.url.lastIndexOf('images') > 0) {
      this.type = "img";
    }
    else if (this.data.url.lastIndexOf('youtube') > 0) {
      this.type = "youtube";
      this.youtubeId = this.getYoutubeId(this.url);
    }
    else {
      this.type = "video";
    }
  }

  getYoutubeId(url: string) {
    let video_id = url.split('v=')[1];
    const ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition != -1) {
      return video_id = video_id.substring(0, ampersandPosition);
    }
    else {
      return video_id;
    }
  }

}
