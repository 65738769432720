import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';
import { ViewMediaModule } from './components/view-media/view-media.module';
import { RegisterSuccessMessageModule } from './components/register-success-message/register-success-message.module';

// FIREBASE
import { AngularFireModule } from "angularfire2";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireStorageModule } from "angularfire2/storage";
import { config } from "./config/config"
import { RouterModule } from '@angular/router';
import { AuthInterceptor } from '@services/interceptor/interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EventAlertComponent } from './components/event-alert/event-alert.component';
import { PromptComponent } from './pages/prompt/prompt.component';
import { PwaService } from '@services/pwa.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}

const initializer = (pwaService: PwaService) => () => pwaService.promptAppStoreRedirect()

@NgModule({
  declarations: [
    AppComponent,
    EventAlertComponent,
    PromptComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HeaderModule,
    FooterModule,
    ViewMediaModule,
    RegisterSuccessMessageModule,
    AngularFireModule.initializeApp(config.FIREBASE_OPTIONS),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    RouterModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: config.lang,
      loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpClient] }
    }),
  ],
  providers: [
    AngularFireAuth,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
