<div class="container-header disabled-mobile bootstrap-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12 container-header-content">
        <img class="logo pointer" src="/assets/logo.png" (click)="goToRoute(config.router.home)" alt="">
        <a class="link-header" (click)="goToRoute(item.section)" *ngFor="let item of items;"
          [ngClass]="{ 'link-active' :  currentUrl === item.section}"> {{ item.name | translate }} </a>
        <span class="example-spacer"></span>
        <div class="container-input" *ngIf="false">
          <input type="text" placeholder="{{'SEARCH' | translate}}">
          <mat-icon class="color-red" aria-hidden="false" aria-label="Example home icon">search</mat-icon>
        </div>
        <div class="container-networks">
          <div class="container-icon pointer icon-networks">
            <i class="fa fa-facebook" aria-hidden="true" (click)="goToUrl('https://facebook.com/TXStyleWrestling')"></i>
          </div>
          <div class="container-icon pointer icon-networks">
            <i class="fa fa-twitter" aria-hidden="true" (click)="goToUrl('https://twitter.com/TSWPlus')"></i>
          </div>
          <div class="container-icon pointer icon-networks">
            <i class="fa fa-instagram" aria-hidden="true" (click)="goToUrl('https://www.instagram.com/tswplus/')"></i>
          </div>
          <div class="container-icon pointer icon-networks">
            <i class="fa fa-youtube" aria-hidden="true" (click)="goToUrl('https://youtube.com/@TexasStyleWrestling')"></i>
          </div>
        </div>
        <div class="container-icon-header">
          <mat-icon class="pointer" [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="Example home icon">
            account_circle</mat-icon>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="isLogin" (click)="goToRoute(config.router.profile + '/my-account')">
              <mat-icon>account_circle</mat-icon>
              <span>{{'PROFILE' | translate}}</span>
            </button>
            <button mat-menu-item *ngIf="isLogin" (click)="logout()">
              <mat-icon>logout</mat-icon>
              <span>{{'LOGOUT' | translate}}</span>
            </button>
            <button mat-menu-item *ngIf="!isLogin" (click)="goToRoute(config.router.login)">
              <mat-icon>login</mat-icon>
              <span>{{'LOGIN' | translate}}</span>
            </button>
            <button mat-menu-item *ngIf="!isLogin" (click)="goToRoute(config.router.register)">
              <mat-icon>app_registration</mat-icon>
              <span>{{'SIGN_UP' | translate}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-toolbar class="disabled-desktop">
  <div class="mobile-header-container">
    <div class="nav-logo-container">
    <button mat-icon-button aria-label="Example icon-button with menu icon" class="nav-btn">
      <mat-icon class="menu-icon" (click)="menuOpen = !menuOpen;">menu</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <img class="logo-mobile" src="/assets/logo.png" alt="">
    <span class="example-spacer"></span>
</div>
          <div class="container-networks container-networks-mobile">
            <div class="container-icon pointer icon-networks">
              <i class="fa fa-facebook" aria-hidden="true" (click)="goToUrl('https://facebook.com/TXStyleWrestling')"></i>
            </div>
            <div class="container-icon pointer icon-networks">
              <i class="fa fa-twitter" aria-hidden="true" (click)="goToUrl('https://twitter.com/TSWPlus')"></i>
            </div>
            <div class="container-icon pointer icon-networks">
              <i class="fa fa-instagram" aria-hidden="true" (click)="goToUrl('https://www.instagram.com/tswplus/')"></i>
            </div>
            <div class="container-icon pointer icon-networks">
              <i class="fa fa-youtube" aria-hidden="true" (click)="goToUrl('https://youtube.com/@TexasStyleWrestling')"></i>
            </div>
    <button mat-icon-button class="icon-rigth" [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with share icon">
      <mat-icon>account_circle</mat-icon>
    </button>
    </div>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="isLogin" (click)="goToRoute(config.router.profile + '/my-account')">
      <mat-icon>account_circle</mat-icon>
      <span>Profile</span>
    </button>
    <button mat-menu-item *ngIf="isLogin" (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>Log Out</span>
    </button>
    <button mat-menu-item *ngIf="!isLogin" (click)="goToRoute(config.router.login)">
      <mat-icon>login</mat-icon>
      <span>Login</span>
    </button>
    <button mat-menu-item *ngIf="!isLogin" (click)="goToRoute(config.router.register)">
      <mat-icon>app_registration</mat-icon>
      <span>Register</span>
    </button>
  </mat-menu>
</mat-toolbar>
<div class="menu-left" [ngClass]="{'active-menu': menuOpen}">
  <div class="container-items">
    <div class="container-input" *ngIf="false">
      <input type="text" placeholder="{{'SEARCH'| translate}}">
      <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
    </div>
    <a class="link-header" (click)="goToRoute(item.section)" *ngFor="let item of items;"
      [ngClass]="{ 'link-active' :  currentUrl === item.section}"> {{ item.name | translate }} </a>
  </div>
</div>
